.checkbox {
  margin-bottom: 22px;
}

.checkboxLabel {
  padding-left: 10px;
}

.button {
  margin-top: 30px;
}
