@import "designPalette.pcss";

.component {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.timer {
  margin-top: 15px;

  width: 100%;
}

.text,
.icon {
  color: var(--lynch);
}
