@font-face {
  font-family: "Beeline Sans";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("./fonts/Beeline-Sans_Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Beeline Sans";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("./fonts/Beeline-Sans_Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Beeline Sans";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("./fonts/Beeline-Sans_Bold.woff2") format("woff2");
}
