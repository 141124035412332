.title {
  margin-top: 12px;
}

.text {
  margin: 12px 0 30px;
}

.altButton {
  margin-top: 14px !important;
}
