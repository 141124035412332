@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  margin: 0;
  padding: 16px 24px;

  overflow: hidden;
  width: 100%;
  min-width: 200px;
  height: 56px;

  white-space: nowrap;

  border: none;
  border-radius: 12px;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
}

.primary {
  background: var(--yellow);

  &:active {
    background: var(--yellow-secondary);
  }
}

.secondary {
  border: 1px solid var(--alto);
  background: var(--white);

  .text {
    @media (--theme-dark) {
      color: var(--white) !important;
    }
  }

  &:active {
    background: var(--athens-gray);
  }

  @media (--theme-dark) {
    background: var(--cod-grey);

    &:active {
      background: rgb(255 255 255 / 12%);
    }
  }
}

.alternative {
  background: var(--blue-disc);

  &:hover {
    background: var(--blue-disc-hover);
  }

  &:active {
    background: var(--blue-disc-pressed);
  }

  .text {
    color: var(--white) !important;

    @media (--theme-dark) {
      color: var(--white) !important;
    }
  }
}

.component:disabled {
  opacity: 0.6;

  cursor: not-allowed;
}

/* Hover styles for Not TouchScreens devices */
@media (--not-touchscreen) {
  .primary:hover {
    background: var(--yellow-secondary);
  }

  .secondary:hover {
    background: var(--athens-gray);

    @media (--theme-dark) {
      background: rgb(255 255 255 / 8%);
    }
  }

  .primary:disabled:hover {
    background: var(--yellow);
  }

  .secondary:disabled:hover {
    background: var(--white);

    @media (--theme-dark) {
      background: var(--cod-grey);
    }
  }
}

.text {
  color: var(--black) !important;
}
