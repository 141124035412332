@import "~normalize.css";
@import "./styles/fonts.pcss";

* {
  box-sizing: border-box;

  font-family: "Beeline Sans", -apple-system, sans-serif !important;

  outline: none;
}

html,
body,
#root {
  min-height: 100%;
}

html {
  transition: background-color 0.3s;
}

body {
  position: relative;

  overflow-x: hidden;

  transition: color 0.25s;
}

p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

.fix-scroll {
  overflow: hidden !important;
}

.fix-scroll-ios {
  height: 100vh !important;
  -webkit-overflow-scrolling: touch;
}

.fix-scroll-ios > body {
  overflow: hidden;
  height: 100vh !important;
  -webkit-overflow-scrolling: touch;
}
