.component {
  position: relative;

  display: flex;

  label,
  input {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
  }
}
