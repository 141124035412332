.component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  overflow: auto;
  width: 100%;
  height: 100%;

  background: rgb(30 32 32 / 95%);

  animation-name: background-opacity;
  animation-duration: 0.5s;

  &.scrollOverlay {
    /* Only supported in WebKit-based and Blink-based browsers */
    overflow: overlay;
  }
}

@keyframes background-opacity {
  0% {
    background: rgb(30 32 32 / 0%);
  }

  100% {
    background: rgb(30 32 32 / 95%);
  }
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
  .component {
    background-color: rgb(30 32 32 / 80%);
    backdrop-filter: blur(14px);
  }
}
