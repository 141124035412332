@import "designPalette.pcss";
@import "mixins.pcss";
@import "vars.pcss";

.component {
  padding: 45px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.title,
.description {
  font-family: -apple-system, sans-serif !important;
  text-align: center;

  color: var(--black) !important;
}

.title {
  margin-top: 20px;

  text-transform: uppercase;
}

.description {
  margin: 12px 0 30px;
}

.button {
  @mixin reset-button;
  padding: 15px;

  font-family: -apple-system, sans-serif !important;
  text-align: center;

  color: var(--white) !important;
  border-radius: 4px;
  background-color: var(--black);

  @media (--tablet) {
    width: 300px;
  }
}