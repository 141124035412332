@import "designPalette.pcss";
@import "themes.pcss";

.form {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 20px;
}

.field:not(:first-of-type) {
  margin-left: 9px;
}

.timer {
  margin-top: 20px;
}

.icon {
  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.error {
  margin-top: 6px;
}
