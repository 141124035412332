@import "designPalette.pcss";
@import "themes.pcss";

.component {
  margin: 0 auto;

  width: 48px;
  height: 48px;

  border-radius: 50%;
  background: #fff;
  background: linear-gradient(to right, var(--gorse) 10%, rgb(255 255 255 / 0%) 42%);

  animation: rotate 0.6s infinite linear;
}

.component::before {
  position: absolute;
  top: 0;
  left: 0;

  width: 50%;
  height: 50%;

  border-radius: 100% 0 0;
  background: transparent;

  content: "";
}

.component::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  width: 80%;
  height: 80%;

  border-radius: 50%;
  background: var(--white);

  @media (--theme-dark) {
    background: var(--cod-grey);
  }

  content: "";
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
