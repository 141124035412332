.title {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 16px;
}

.date-input,
.checkbox-wrapper {
  margin-bottom: 30px;
}
