@import "designPalette.pcss";
@import "themes.pcss";
@import "vars.pcss";

.title {
  margin-bottom: 30px;

  @media (--tablet) {
    margin-bottom: 24px;
  }
}

.detail {
  border-bottom: 1px solid var(--gallery-grey);

  @media (--theme-dark) {
    border-bottom: 1px solid var(--grey-bright);
  }
}

.detail:last-child {
  border-bottom: none;
}

.button {
  margin-top: 30px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.warning {
  margin-top: 4px;
}
