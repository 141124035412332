@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding-top: 20px;
}

.logo {
  width: 130px;
  height: 50px;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.children.loading {
  display: none;
}
