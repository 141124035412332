@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: relative;

  display: inline-block;
  vertical-align: middle;

  &:hover,
  &:focus {
    z-index: 10;

    & .arrow,
    & .container {
      display: block;
    }
  }
}

.tooltip:not(.label) {
  position: relative;

  width: 22px;
  height: 22px;

  border-radius: 50%;
  background: var(--grey-light);

  cursor: default;
}

.svg {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.arrow {
  position: absolute;
  top: 30px;
  left: 50%;
  padding: 6px;

  display: none;

  border-radius: 3px;
  background-color: var(--cod-grey);

  @media (--theme-dark) {
    background-color: var(--arsenic);
  }

  transform: rotate(45deg) translateX(-50%);
  transform-origin: 0;

  &.top {
    top: -25px;
  }
}

.container {
  position: absolute;
  top: 0;
  left: 50%;
  padding-top: 34px;

  display: none;
  max-width: 87.5vw;

  transform: translateX(-50%);

  &.top {
    top: initial;
    bottom: 100%;
    padding-top: 0;
    padding-bottom: 18px;
  }
}

.text {
  position: relative;
  padding: 14px 20px;

  overflow: auto;

  white-space: pre-line;

  border-radius: 8px;
  background-color: var(--cod-grey);

  @media (--theme-dark) {
    background-color: var(--arsenic);
  }
}
