@import "designPalette.pcss";
@import "themes.pcss";

.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.6;
  color: var(--black);
  border: none;
  background: none;

  transform: translateY(-50%);

  @media (--theme-dark) {
    color: var(--white);
  }
}
