@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.radio {
  position: relative;

  border-width: 1px;
  border-style: solid;
  border-color: var(--mischka);
  border-radius: 18px;
  background: var(--athens-gray);

  cursor: pointer;
  appearance: none;

  @media (--theme-dark) {
    border-color: var(--lynch);
    background: var(--tuna);
  }
}

.radio:checked {
  border-color: var(--yellow);
  background-color: var(--yellow);

  transition: background-color 150ms linear;

  @media (--not-touchscreen) {
    &:hover {
      border-color: var(--yellow-secondary);
      background-color: var(--yellow-secondary);
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 10px;
    height: 10px;

    border-radius: 10px;
    background-color: var(--black);

    transform: translate(-50%, -50%);

    content: "";
  }
}

.radio[data-invalid="true"] {
  border-color: var(--persimmon);
}

.radio:disabled {
  opacity: 0.55;
}
