@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "mixins.pcss";

.component {
  padding: 20px;
}

.title {
  margin-bottom: 30px;

  font-family: -apple-system, sans-serif !important;
  text-transform: uppercase;

  color: var(--black) !important;
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  @media (--breakpoint-400) {
    gap: 38px;
  }

  @media (--breakpoint-640) {
    gap: 48px;
  }
}

.button {
  @mixin reset-button;

  box-sizing: border-box;
  width: 50px;
  height: 50px;

  border: 1px solid #dedede;
  border-radius: 6px;
}

.other {
  position: relative;

  width: 70px;

  cursor: pointer;
}

.other::after {
  position: absolute;
  top: 50%;
  right: -12px;

  width: 1px;
  height: 40px;

  background-color: #dfe0e5;

  transform: translateY(-50%);

  content: "";

  @media (--breakpoint-400) {
    right: -19px;
  }

  @media (--breakpoint-640) {
    right: -24px;
  }
}

.icon {
  width: 24px;
  height: 28px;
}
