@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 10;
  margin: 0;
  padding: 18px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 109px;

  border-radius: 12px;
  background: var(--white);
  box-shadow: 0 0 14px rgb(34 32 129 / 8%);

  @media (--theme-dark) {
    background: var(--athens-gray);
    box-shadow: none;
  }

  @media (--theme-dark) {
    background: var(--tuna);
  }
}

.container {
  margin: 0;
  padding: 0;

  overflow-y: auto;
  width: 100%;
  max-height: 73px;

  list-style: none;
}

.container::-webkit-scrollbar {
  width: 5px;
}

.container::-webkit-scrollbar-track {
  opacity: 0.6;
  border-radius: 5px;
  background: var(--athens-gray);

  @media (--theme-dark) {
    background: var(--black);
  }
}

.container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--bright-grey);

  @media (--theme-dark) {
    background: var(--lynch);
  }
}

.option {
  margin-right: 26px;
}

.option:not(:first-of-type) {
  padding-top: 14px;

  border-top: 1px solid var(--gallery-grey);

  @media (--theme-dark) {
    border-color: rgb(255 255 255 / 60%);
  }
}

.option:not(:last-of-type) {
  padding-bottom: 14px;
}

.text {
  font: var(--fs-m)/22px "Beeline Sans", -apple-system, sans-serif;
  font-weight: 400;
  text-overflow: ellipsis;
}

.button {
  width: 100%;

  border: none;
  background: transparent;
}
