@import "designPalette.pcss";
@import "themes.pcss";

.container {
  padding: 40px;

  display: flex;
  flex-direction: column;
  max-width: 376px;

  border-radius: 8px;
  background: var(--white);

  @media (--theme-dark) {
    background: var(--cod-grey);
  }
}

.title {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 32px;
}
