.value {
  font-size: 20px;
  line-height: 35px;

  user-select: none;
}

.with-padding {
  padding: 0 20px;
}

.centered {
  text-align: center;
}

.component {
  margin-right: 0;
  margin-left: 0;

  flex: 1;
}
