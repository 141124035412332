@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  font-family: "Beeline Sans", -apple-system, sans-serif;
  font-weight: var(--weight-400);
  -webkit-font-smoothing: antialiased;

  text-align: left;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }

  a:not(:hover) {
    color: var(--blue);

    @media (--theme-dark) {
      color: var(--dark-blue);
    }
  }
}

.s {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.regular {
  font-size: var(--fs-regular);
  line-height: var(--lh-regular);
}

.m {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}

.l {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.error { color: var(--red); }
.inverse { color: var(--white); }
.secondary { color: var(--regent-grey); }
.placeholder { color: var(--jumbo); }

.bold500 { font-weight: var(--weight-500); }
.bold700 { font-weight: var(--weight-700); }

.center {
  margin-right: auto;
  margin-left: auto;

  text-align: center;
}
