.title {
  margin-bottom: 30px;
}

.button {
  margin-top: 30px;
}

.input + .input {
  margin-top: 10px;
}
