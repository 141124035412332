@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding-top: 20px;

  width: 100%;
  max-width: 380px;
  height: auto;

  background: var(--white);

  @media (--theme-dark) {
    background: var(--cod-grey);
  }

  @media (orientation: landscape) {
    max-height: none;
  }

  @media (--breakpoint-640) {
    position: relative;
    margin-bottom: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;

    min-height: 300px;

    border-radius: 8px;
    box-shadow: var(--shadow);

    @media (--theme-dark) {
      box-shadow: var(--dark-shadow);
    }
  }
}
