@import "breakpoints.pcss";

.title {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 30px;
}

.phone-input {
  margin-bottom: 19px;

  @media (--breakpoint-640) {
    margin-bottom: 23px;
  }
}

.legal-text {
  margin-top: 24px;
}
