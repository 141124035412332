.container {
  display: flex;
  flex-direction: column;

  min-height: 350px;
}

.title {
  margin-bottom: 13px;
}

.checkbox-wrapper {
  margin-top: 20px;
}

.error {
  margin-top: 20px;
}

.button {
  margin-top: 35px;
}

.preloader {
  margin: auto;
}