@import "designPalette.pcss";

.overlay {
  padding: 15px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  text-align: center;

  &::before,
  &::after {
    content: "";
  }
}

.overlay.centered {
  &::before {
    flex: 3 1 auto;
  }

  &::after {
    flex: 5 1 auto;
  }
}

.overlay.on-top {
  &::before {
    flex: 0 1 auto;
  }

  &::after {
    flex: 3 1 auto;
  }
}

.overlay.on-bottom {
  &::before {
    flex: 5 1 auto;
  }

  &::after {
    flex: 0 1 auto;
  }
}

.component {
  position: relative;
  z-index: 2;

  display: flex;
  flex: 0 1 auto;
  justify-content: center;

  border-radius: 24px;

  animation-name: slideFromTop;
  animation-duration: 0.5s;

  &.wide {
    width: 100%;
  }
}

.cross {
  position: absolute;
  top: 27px;
  right: 20px;
  padding: 0;

  font-size: 0;

  border: none;
  background: none;

  cursor: pointer;

  &.hideCloseButton {
    visibility: hidden;
  }
}

.icon {
  width: 12px;
  height: 12px;

  transition: color 0.15s;
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}
