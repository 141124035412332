@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.footer {
  margin: 50px 0 24px;

  align-self: baseline;

  text-align: center;

  background: transparent;

  @media (--breakpoint-640) {
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
  }
}

.logo {
  color: var(--nero);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.about {
  margin-top: 24px;

  @media (--breakpoint-640) {
    margin-top: 15px;
  }
}
