@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding-top: 20px;
}

.logo {
  width: 130px;
  height: 50px;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.preloader {
  position: absolute;
  top: calc(50%);
  left: calc(50%);

  width: 100%;

  transform: translate(-50%, -50%);
}

.children.loading {
  display: none;
}

.loading-message {
  margin-top: 22px;
}
