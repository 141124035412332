@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.link {
  text-decoration: none;

  color: var(--blue);
  border-bottom: 1px solid transparent;

  @media (--theme-dark) {
    color: var(--dark-blue);
  }
}

.link:visited {
  color: inherit;
}

@media (--not-touchscreen) {
  .link:hover {
    color: var(--orange);
    border-bottom-color: var(--orange);
  }
}
