@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding: 10px 15px;

  display: flex;
  align-items: center;

  border-radius: 5px;
  background: rgb(253 216 53 / 20%);

  @media (--theme-dark) {
    background: rgb(145 152 160 / 10%);
  }
}

.icon {
  margin-right: 10px;

  flex-shrink: 0;

  @media (--theme-dark) {
    color: var(--white);
  }
}

.text {
  font-size: 14px;
  line-height: 17px;
}
