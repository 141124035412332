.title {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 30px;
}

.code-input {
  margin-bottom: 30px;
}

.button {
  margin-bottom: 20px;
}

.legal {
  margin-top: 30px;
}
