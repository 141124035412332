.container {
  position: relative;

  min-height: 100vh;
}

.preloader {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);

  transform: translate(-50%, -50%);
}
