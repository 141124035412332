@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.layout {
  padding: 20px 30px 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;

  background: var(--white);

  @media (--breakpoint-640) {
    padding: 80px 40px 35px;

    background: var(--athens-gray);
  }

  @media (--theme-dark) {
    background: var(--cod-grey);

    @media (--breakpoint-640) {
      background: var(--swamp);
    }
  }
}
