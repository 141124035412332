@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: relative;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  box-sizing: border-box;
  width: 42px;
  height: 53px;

  border-radius: 12px;
  background: var(--athens-gray);

  @media (--theme-dark) {
    background: var(--tuna);
  }
}

.isActive.isFocused {
  border: 1px solid var(--jumbo);

  @media (--theme-dark) {
    border-color: var(--athens-gray);
  }
}

.isActive.isEmpty::after {
  position: absolute;
  top: 50%;
  left: 50%;

  color: var(--black);

  transform: translate(-50%, -50%);
  animation-name: blink;
  animation-duration: 895ms;
  animation-iteration-count: infinite;

  content: "|";
  animation-fill-mode: both;

  @media (--theme-dark) {
    color: var(--white);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
