@define-mixin reset-button {
  margin: 0;
  padding: 0;

  overflow: hidden;
  width: 100%;

  white-space: nowrap;

  border: none;
  background: none;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
}
