@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.title {
  margin-bottom: 12px;
}

.text {
  margin-bottom: 33px;
}

.inputs {
  overflow-y: auto;
  max-height: 400px;
}

.inputs::-webkit-scrollbar {
  display: none;
}

.input + .input {
  margin-top: 10px;
}

.button {
  margin-top: 20px;
}

.wrapper {
  animation: slide 3s forwards;
}

@keyframes slide {
  0% {
    transform: translateY(-75%);
  }

  100% {
    transform: translateY(0);
  }
}
