@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: relative;
  padding: 16px 47px 16px 16px;

  width: 100%;

  color: var(--jumbo);
  border: 1px solid transparent;
  border-radius: 12px;
  background: var(--athens-gray);

  @media (--theme-dark) {
    color: var(--white);
    background: var(--tuna);
  }
}

.hasValue {
  padding-top: 22px;
  padding-bottom: 10px;
}

.label {
  position: absolute;
  top: 50%;
  left: 5px;
  padding: 0;

  background: transparent;

  transform: translate(12px, -22px) scale(0.85);
  transform-origin: 0 50%;

  pointer-events: none;
}

.arrow-icon {
  position: absolute;
  top: 16px;
  right: 14px;

  color: var(--black);

  transition: transform 400ms;
  transform: rotate(180deg);

  pointer-events: none;

  @media (--theme-dark) {
    color: var(--white);
  }
}

.arrow-icon.isOpen {
  transform: rotate(0deg);
}

.text {
  font: var(--fs-m)/22px "Beeline Sans", -apple-system, sans-serif;
  font-weight: 400;
  text-overflow: ellipsis;
}

.label,
.placeholder {
  color: rgb(0 0 0 / 60%);

  @media (--theme-dark) {
    color: rgb(255 255 255 / 30%);
  }
}

.input {
  display: none;
}

.isError {
  border-color: var(--persimmon);
  background: var(--error-background);
}
