.preloader {
  position: absolute;
  top: calc(50%);
  left: calc(50%);

  width: 100%;

  transform: translate(-50%, -50%);
}

.loading-message {
  margin-top: 22px;
}
