@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: absolute;
  top: 0;
  left: -80px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  color: var(--regent-grey);
  border: 1px solid currentColor;
  border-radius: 8px;
  background: transparent;

  cursor: pointer;

  svg {
    width: 12px;
    height: 20px;
  }

  @media (--theme-dark) {
    color: var(--lynch);
  }
}
