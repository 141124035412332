@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  margin: 0;

  font-family: "Beeline Sans", -apple-system, sans-serif;
  font-weight: var(--weight-700) !important;
  -webkit-font-smoothing: antialiased;

  text-align: left;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.s {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.regular {
  font-size: var(--fs-regular);
  line-height: var(--lh-regular);
}

.m {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}

.l {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.center {
  margin-right: auto;
  margin-left: auto;

  text-align: center;
}
