@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--athens-gray) inset !important;

  @media (--theme-dark) {
    border-color: var(--tuna);
    box-shadow: 0 0 0 30px var(--tuna) inset !important;
    -webkit-text-fill-color: var(--white);
  }
}

input {
  appearance: none;
}

.component {
  position: relative;

  display: block;

  & + & {
    margin-top: 12px;
  }
}

.label {
  position: absolute;
  top: 50%;
  left: 5px;
  padding: 0;

  font: var(--fs-m)/var(--lh-m) "Beeline Sans", -apple-system, sans-serif;

  background: transparent;

  transition: transform 0.2s;
  transform: translate(12px, -50%);

  pointer-events: none;
}

.input {
  margin: 0;
  padding: 22px 16px 8px;

  width: 100%;

  font-size: var(--fs-m);
  font-weight: var(--weight-400);
  line-height: var(--lh-m);

  color: var(--black);
  border: 1px solid transparent;
  border-radius: 12px;
  background: var(--athens-gray);

  @media (--theme-dark) {
    color: var(--white);
    background: var(--tuna);
  }
}

.input::placeholder {
  font-size: var(--fs-m);
  font-weight: var(--weight-400);
  line-height: var(--lh-m);

  opacity: 0;
  color: rgb(0 0 0 / 60%);

  @media (--theme-dark) {
    color: rgb(255 255 255 / 60%);
  }
}

.input:not(.preventLabelAnimation):focus {
  &::placeholder {
    opacity: 1;
  }
}

.input[data-invalid="true"] {
  border-color: var(--persimmon);
  background: var(--error-background);
}

.input:not(.preventLabelAnimation):focus + .label,
.input-not-empty + .label {
  padding: 0;

  font-size: var(--fs-s);
  line-height: var(--lh-s);

  transform: translate(12px, -22px) scale(0.85);
  transform-origin: 0 50%;
}

.input:disabled {
  opacity: 0.5;

  cursor: not-allowed;

  & + .label {
    opacity: 0.5;
  }
}
