@import "vars.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.checkbox {
  position: relative;

  border-width: 1px;
  border-style: solid;
  border-color: var(--mischka);
  border-radius: 6px;
  background: var(--athens-gray);

  cursor: pointer;
  appearance: none;

  @media (--theme-dark) {
    border-color: var(--lynch);
    background: var(--tuna);
  }
}

.checkbox:checked {
  border-color: var(--yellow);
  background-color: var(--yellow);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M6.103 8.538L13.975.57l1.423 1.406-9.343 9.455L.57 5.501l1.468-1.358 4.064 4.394z' fill='%23313031'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  transition: background-color 150ms linear;

  @media (--not-touchscreen) {
    &:hover {
      border-color: var(--yellow-secondary);
      background-color: var(--yellow-secondary);
    }
  }
}

.checkbox[data-invalid="true"] {
  border-color: var(--persimmon);
}

.checkbox:disabled {
  opacity: 0.55;
}

.label {
  margin-left: 10px;

  cursor: pointer;
}
