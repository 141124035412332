@import "designPalette.pcss";
@import "themes.pcss";

.component {
  position: relative;
  padding: 15px 0 10px;
}

.clickable:hover {
  cursor: pointer;
}

.label {
  font-size: 15px !important;
  line-height: 18px !important;
}

.icon {
  position: absolute;
  top: 35px;
  right: 0;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}

.label-container {
  margin-bottom: 6px;

  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;

  border-radius: 15px;
  background-color: var(--arsenic);
}

.tooltip-label {
  color: var(--white);
}
