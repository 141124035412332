@import "breakpoints.pcss";
@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding-top: 20px;

  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  height: auto;

  background: var(--white);

  @media (--theme-dark) {
    background: var(--cod-grey);
  }

  @media (orientation: landscape) {
    max-height: none;
  }

  @media (--breakpoint-640) {
    position: relative;
    margin-bottom: 50px;
    padding: 40px 40px 50px;

    flex: initial;
    min-height: 300px;

    border-radius: 8px;
    box-shadow: var(--shadow);

    @media (--theme-dark) {
      box-shadow: var(--dark-shadow);
    }
  }
}

.children {
  margin: auto 0;
  padding-top: 20px;

  @media (--breakpoint-640) {
    margin: auto;
  }
}

.logo {
  width: 30px;
  height: 30px;

  color: var(--black);

  @media (--theme-dark) {
    color: var(--white);
  }
}
