@import "designPalette.pcss";
@import "themes.pcss";

.component {
  padding: 20px;

  display: flex;
  flex-direction: column;
  width: 355px;
  max-width: 355px;

  border-radius: 8px;
  background: var(--white);
  box-shadow: 0 0 14px rgb(34 32 129 / 8%);

  @media (--theme-dark) {
    background: var(--cod-grey);
  }
}

.label {
  margin-bottom: 10px;
}

.container {
  position: relative;
  margin-bottom: 6px;

  display: flex;
  overflow: hidden;
  height: 175px;
}

.container::before {
  position: absolute;
  top: 50%;
  left: 0;

  width: 100%;
  height: 35px;

  border-radius: 6px;
  background: var(--athens-gray);

  transform: translateY(-50%);

  content: "";

  @media (--theme-dark) {
    background: var(--tuna);
  }
}

.container::after {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 65px;

  background: linear-gradient(to top, var(--white), transparent);

  content: "";

  @media (--theme-dark) {
    background: linear-gradient(to top, var(--cod-grey), transparent);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 65px;
}

.overlay::before {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 65px;

  background: linear-gradient(to bottom, var(--white), transparent);

  content: "";

  @media (--theme-dark) {
    background: linear-gradient(to bottom, var(--cod-grey), transparent);
  }
}

.error {
  margin-top: 10px;
}

.months {
  flex: 1.3;
}
