.title {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 20px;
}

.method {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text {
  font-size: 18px;
}

.tooltip {
  margin-top: 8px;
}

.button {
  margin-top: 20px;
}
