.link {
  display: block;

  text-decoration: none;

  color: inherit;

  &:visited {
    color: inherit;
  }
}
